import Vue from 'vue'
import Router from 'vue-router'

function load(component) {
  // '@' is aliased to src/components
  return () => import(`@/components/${component}.vue`)
}

function loadPage(component, path = '') {
  // '@' is aliased to src/module
  let importPath = (path) ? path + '/' : '';
  return () => import(`@/components/page/` + importPath + `${component}.vue`)
}

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: load('Main'),
      children: [
        // 이벤트
        {
          path: '/event',
          component:loadPage('Event')
        },
        //signin
        {
          path:'/signin',
          component:loadPage('Signin')
        },
        //signup
        {
          path:'/signup',
          component:loadPage('Signup')
        },
        //이메일인증
        {
          path:'/signemail',
          component:loadPage('SignEmail')
        },
        //findps
        {
          path:'/findpw',
          component:loadPage('FindPw')
        },
        //비로그인시 테마신청완료
        {
          path:'/isnotlogintheme',
          component:loadPage('IsNotLoginTheme')
        },
        {
          path: '/pg',
          component: loadPage('Pg')
        },
        {
          path: '/store',
          component: loadPage('Store')
        },
        {
          path:'/store/detail',
          component:loadPage('StoreDetail')
        },
        {
          path:'/solution',
          component:loadPage('Solution')
        },
        {
          path: '/service_create',
          component: loadPage('ServiceCreate'),
        },
        {
          path: 'home',
          component: loadPage('Home')
        },
        {
          path: '/theme_market',
          component: loadPage('ThemeMarket')
        },
        {
          path: '/theme_detail',
          component: loadPage('ThemeDetail')
        },
        {
          path: '/service_survey',
          component: loadPage('ServiceSurvey')
        },
        {
          path: '/trial_account',
          component: loadPage('TrialAccount')
        },
        {
          path: '/processing',
          component: loadPage('Processing')
        },
        {
          path: 'info',
          component: loadPage('Info')
        },
        {
          path: '/platformfy_info',
          component: loadPage('PlatformfyInfo')
        },
        {
          path: '/appmarket_info',
          component: loadPage('AppmarketInfo')
        },
        {
          path: 'solution_info',
          component: loadPage('SolutionInfo')
        },
        {
          path: 'guide',
          component: loadPage('Guide')
        },
        {
          path: 'order',
          component:loadPage('ServiceOrder')
        },
        {
          path: 'service_account',
          component: loadPage('ServiceAccount')
        },
        {
          path: 'service_account_done',
          component: loadPage('ServiceAccountDone')
        },
        {
          path: 'preorder',
          component: loadPage('Preorder')
        },
        {
          path: 'cs',
          component: loadPage('CsCenter')
        },
        {
          path: 'account_done',
          component: loadPage('AccountDone')
        },
        {
          path: 'account',
          component: loadPage('Account')
        },
        {
          path: '/billing',
          component: loadPage('Billing')
        },
        {
          path: '/bill_done',
          component: loadPage('BillDone')
        },
        {
          path: '/verify',
          component: loadPage('Verify')
        },
        {
          path: '/price',
          component: loadPage('Price')
        },
        {
          path: '/notice',
          component: loadPage('Notice')
        },
        {
          path: '/notice/detail',
          component: loadPage('NoticeDetail')
        },
        {
          path: '/faq',
          component: loadPage('Faq')
        },
        {
          path: '/inquiry',
          component: loadPage('PersonalInquiry')
        },
        {
          path: '/inquiry_reg',
          component: loadPage('PersonalInquiryReg')
        },
        {
          path: '/inquiry/detail',
          component: loadPage('PersonalInquiryDetail')
        },
        {
          path: '/service_inquiry_guide',
          component: loadPage('ServiceInquiryGuide')
        },
        {
          path: '/api_guide',
          component: loadPage('ApiGuide')
        },
        {
          path: '/prime',
          component: loadPage('ServiceInquiryReg1')
        },
        {
          path: '/service_inquiry_reg',
          component: loadPage('ServiceInquiryReg')
        },
        {
          path: '/service_inquiry_reg1',
          component: loadPage('ServiceInquiryReg1')
        },
        {
          path: '/service_inquiry_reg_new',
          component: loadPage('ServiceInquiryReg1_new')
        },
        {
          path : '/solution_api_reg',
          component: loadPage('SolutionApiReg')
        },
        {
          path : '/solution_api_detail',
          component: loadPage('SolutionApiDetail')
        },
        {
          path: '/service_inquiry_done',
          component: loadPage('ServiceInquiryDone')
        },
        {
          path: '/post',
          component: loadPage('Post')
        },
        {
          path: '/post/detail',
          component: loadPage('PostDetail')
        },
        {
          path: '/theme/intro',
          component: loadPage('ThemeIntro')
        },
        {
          path: '/theme/guide',
          component: loadPage('ThemeGuide')
        },
        {
          path: '/hosting/intro',
          component: loadPage('HostingIntro')
        },
        {
          path: '/care_service/intro',
          component: loadPage('CareServiceIntro')
        },
        {
          path: '/price_info',
          component: loadPage('PriceInfo')
        },
        {
          path: '/reg_bill_success',
          component: loadPage('RegBillSuccess')
        },
        {
          path:'/mypage',
          component:loadPage('MyPages'),
          children:[
            {
              path: 'profile',
              component:loadPage('Profile'),
            },
            //비밀번호변경
            {
              path:'password',
              component:loadPage('Password')
            },
            {
              path:'my_service',
              component:loadPage('MyService')
            },
            {
              path:'my_coupon',
              component:loadPage('MyCoupon')
            },
            {
              path: 'my_service_detail',
              component: loadPage('MyServiceDetail')
            },
            {
              path:'themerequest',
              component:loadPage('ThemeRequestList')
            },
            {
              path:'themepreorder',
              component:loadPage('ThemePreorderList')
            },
            {
              path:'inquiry',
              component:loadPage('ServiceInquiryList')
            },
            {
              path:'design_inquiry',
              component:loadPage('DesignInquiryList')
            },
            {
              path:'api',
              component:loadPage('SolutionApiList')
            },
            {
              path: 'temporary_storage',
              component: loadPage('TemporaryStoragePage')
            },
          ]
        },
        {
          path: 'service_inquiry_detail',
          component: loadPage('ServiceInquiryDetail')
        },
        {
          path: 'design_inquiry_detail',
          component: loadPage('DesignInquiryDetail')
        },
        {
          path: '/pg_done',
          component: loadPage('PgDone')
        },
        {
          path: '/term',
          component: loadPage('Term', 'policy')
        },
        {
          path: '/privacy',
          component: loadPage('Privacy', 'policy')
        },
        {
          path: '/marketing',
          component: loadPage('Marketing', 'policy')
        },
        {
          path: '/hosting',
          component: loadPage('Hosting', 'policy')
        },
        {
          path: '/digital',
          component: loadPage('Digital', 'policy')
        },
        {
          path: '/temporary_storage',
          component: loadPage('TemporaryStoragePage')
        },
        {
          path: '/service_request',
          component: loadPage('ServiceRequest')
        },
        {
          path: '/service_request_done',
          component: loadPage('ServiceRequestDone')
        },
        {
          path: '/service_request_done2',
          component: loadPage('ServiceRequestDone2')
        }
      ]
    },
    {
      path: '/market',
      component: loadPage('MarketMain'),
      children: [
        {
          path: 'list',
          component:loadPage('Market')
        },
        {
          path:'detail',
          component:loadPage('MarketDetail')
        }
      ]
    },
    {
      path: '/app_market',
      component: loadPage('MarketMain'),
      children: [
        {
          path: '/',
          component: loadPage('AppMarket'),
          children: [
            {
              path:'list',
              component:loadPage('Market')
            },
            {
              path:'order_list',
              component:loadPage('OrderList')
            },
            {
              path:'detail',
              component:loadPage('MarketDetail')
            },
            {
              path: 'inquiry_reg',
              component: loadPage('PersonalInquiryReg')
            },
            {
              path:'account',
              component:loadPage('MarketAccount')
            },
            {
              path:'account_done',
              component:loadPage('MarketAccountDone')
            },
          ]
        }
      ]
    },
    {
      path: '/quickbuilder',
      component: loadPage('QuickBuilder')
    },
    {
      path: '/append_pack',
      component: loadPage('AppendPack')
    },
    {
      path: '/npay',
      component: loadPage('NPay')
    },
    {
      path: '/pg',
      component: loadPage('Pg')
    },
    {
      path: '/pg_done',
      component: loadPage('PgDone')
    },
    {
      path: '/bill_reg',
      component: loadPage('BillReg')
    },
  ],
  // eslint-disable-next-line no-unused-vars
  /*scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  }*/
})
// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  if (to.path === '/')
    router.replace('/home')
})

router.beforeEach((to, from, next) => {
  if(from.meta.clickPush) {
    to.meta.isRefresh = true;
    from.meta.clickPush = false;
  } else {
    to.meta.isRefresh = false;
  }
  to.meta.toPath = to.path;
  to.meta.fromPath = from.path;
  from.meta.toPath = to.path;
  from.meta.fromPath = from.path;
  next();
})

export default router
