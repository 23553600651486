<template>
  <div id="app">
    <router-view />
    <lp-toast></lp-toast>
    <loading v-if="loading.status" :message="loading.message"></loading>
  </div>
</template>

<script>
import Loading from "./components/component/Loading";
import LpToast from "./components/component/LpToast";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

export default {
  name: 'App',
  components: { LpToast, Loading },
  data() {
    return {
      loading: {
        status: false,
        message: ''
      },
      excludePath: [
        'store',
        'app_market/list'
      ]
    }
  },
  created() {
    this.$axios.defaults.baseURL = process.env.VUE_APP_MASTER_BACKEND_URL;
    if (location.href.indexOf('www.') > -1) {
      location.replace(location.href.replace('www.', ''))
    }
    this.setFCM();
  },

  watch: {
    '$route.path'(n) {
      if (location.href.indexOf('www.') > -1) {
        location.replace(location.href.replace('www.', ''))
      }
      if (n.indexOf('/mypage') > -1) {
        if (this.$store.getters.user.user_id === 0) {
          this.$router.replace('/');
        }
      }
      setTimeout(() => {
        if (this.excludePath.indexOf(this.$route.path.substr(1)) === -1)
          window.scrollTo(0, 0);
      }, 280)
    },
    '$store.getters.loadingText'(n) {
      this.loading.status = n !== '';
      this.loading.message = n;
    }
  },
  methods: {
    async setFCM() {
      const firebaseConfig = {
        apiKey: "AIzaSyCUZNqTufYn7fsXS7R5Bk_a-3qhevoDHeA",
        authDomain: "platformfy-82363.firebaseapp.com",
        projectId: "platformfy-82363",
        storageBucket: "platformfy-82363.appspot.com",
        messagingSenderId: "426115265629",
        appId: "1:426115265629:web:b5d119f983b8eb6797c9b4"
      };
      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      const messaging = getMessaging(app);

      try {
        const registration = await navigator.serviceWorker.register('/static/firebase-messaging-sw.js');
        messaging.swRegistration = registration;

        const currentToken = await getToken(messaging, { vapidKey: 'BI7HmIltlYYiXjToIJo3tkXjGpm9yPN8HzFWnEFM63Y6fhegxk5hW1vYBtgSjjyErXAVZXczcS5WfHOeKmm4ego' });
        // console.log('currentToken', currentToken);
        this.$store.commit('setFcmToken', currentToken);

        if (this.$store.getters.user.user_id !== 0) {
          this.setAuthHeader();
        }
      } catch (err) {
        console.error('Error during FCM setup:', err);
      }
    }
  }
}
</script>

<style>
#app {
  font-family: Inter, Noto Sans Korean;
}
</style>
